import { stringify } from 'querystring'
import { sync } from 'vuex-router-sync'
import { Categories, Orders, Products, Suggestions } from '@one/types'
import {
  Wishlist,
  Shared,
} from '@one/types/dist/orderpath/app'
import slugify from 'slugify'
import WishlistProduct = Wishlist.WishlistProduct
import ProductInCart = Shared.ProductInCart

// eslint-disable-next-line max-len
type ProductUT =
  | Products.Product
  | Suggestions.SuggestedProduct
  | ProductInCart
  | Orders.DetailedOrder.DetailedOrderShipmentProduct
  | WishlistProduct

export interface RoutePagination {
  filtersQuery?: Array<string | null>
  page?: number
  rows?: number
  sortCriteria?: string
  price?: string
}

export interface Crumb {
  name: string
  link: string
}

export interface RouterUtils {
  search(query: string, categoryId?: string, pagination?: RoutePagination, cb?: Function): any
  getProductPathById(productId: string): string | undefined
  getProductPath(product: ProductUT): string
  getManufacturerSearchUrl(manufacturer: Products.Manufacturer | string): string
  getManufacturerSearchUrlByCharacteristics(characteristics: Suggestions.CharacteristicValue[]): string
  getCategoryBreadcrumbs(categoryId?: string): Array<string | Crumb>
  getCategoryPath(id?: string): string | null
  getProductUrl(product: ProductUT): string | null | undefined
  removeCategoryFromURL(): void
  parsePriceString(price: string): [number, number] | null
  isExternalUrl(url: string): boolean
}

export default ({ app }: any, inject: any) => {
  const { store, router } = app
  sync(store, router)
  const utils: RouterUtils = {
    search(query: string, categoryId?: string, pagination?: RoutePagination, cb?: Function) {
      const params: any = {
        q: query,
        page: pagination?.page || 1,
        rows: pagination?.rows || 15,
        sortCriteria: pagination?.sortCriteria
          || store.getters['products/getDefaultSortForSearch'],
      }
      if (categoryId) {
        params.category = categoryId
      }
      if (pagination?.filtersQuery) {
        params.filtersQuery = pagination?.filtersQuery
      }
      return router.push(
        {
          path: app.localePath('search'),
          query: params,
        },
        cb ? cb() : () => ({}),
      )
    },
    removeCategoryFromURL() {
      const clonedParams: any = router.currentRoute.query
      delete clonedParams.category
      // eslint-disable-next-line no-restricted-globals
      history.pushState(
        {},
        '',
        // @ts-ignore
        `${router.currentRoute.path}?${stringify(clonedParams)}`,
      )
    },
    getProductPathById(productId: string) {
      const product: Products.Product = store.getters['products/getProduct'](productId)
      if (product) {
        if (!product.url) {
          const slugifiedName = slugify(product.name, {
            lower: true,
          })
          return `/${slugifiedName}-id-${product.slug}`
        }
        return product.url
      }
      return '#'
    },
    getProductPath(product: ProductUT) {
      const slugifiedName = slugify(product.name, {
        lower: true,
      })
      return `/${slugifiedName}-id-${product.slug}`
    },
    getProductUrl(product: ProductUT) {
      return 'currentInfo' in product && product.currentInfo?.url
        ? product.currentInfo?.url
        : this.getProductPath(product)
    },
    getManufacturerSearchUrl(manufacturer: Products.Manufacturer | string) {
      const id = typeof manufacturer === 'string' ? manufacturer : manufacturer.id
      return app.localePath({
        path: `/search?q=%20&filtersQuery=manufacturer:${id}&page=1&rows=15`,
      })
    },
    getManufacturerSearchUrlByCharacteristics(characteristics: Suggestions.CharacteristicValue[]) {
      const name: string = characteristics.find(el => el.characteristicCode = 'manufacturer')?.valueCode ?? ''
      return app.localePath({
        path: `/search?q=%20&filtersQuery=manufacturer:${name}&page=1&rows=15`,
      })
    },
    getCategoryBreadcrumbs(categoryId?: string) {
      const getCategory = store.getters['categories/getCategory']
      const currentCategory = store.getters['categories/getSelectedCategory']
      const category: Categories.Category | null = getCategory(categoryId) || currentCategory
      const crumbs: Array<Crumb> = []
      const traverse = (category: Categories.Category) => {
        crumbs.unshift({
          name: category.name,
          link: category.url,
        })
        if (category.parent) {
          traverse(getCategory(category.parent))
        }
      }
      if (category) {
        traverse(category)
      }
      return crumbs
    },
    parsePriceString(price: string): [number, number] | null {
      const regexGroups: Array<string> | null = new RegExp(
        '(?:NET|GROSS):([0-9]{1,7})-([0-9]{1,7})',
      ).exec(price)
      if (regexGroups && regexGroups.length) {
        return [Number(regexGroups[1]), Number(regexGroups[2])]
      }
      return null
    },
    getCategoryPath(id?: string) {
      const state = store.state.categories
      const categoryId: string | null = id || state.selectedCategoryId
      if (!categoryId) {
        return null
      }
      const category: Categories.Category = state.categories[categoryId]
      if (category) {
        return category.url
      }
      return null
    },
    isExternalUrl(url: string | undefined): boolean {
      return typeof url === 'string' && !(url.startsWith('/') || url.startsWith('#'))
    },
  }
  inject('routes', utils)
}
