import Vue from 'vue'
import { DirectiveBinding } from 'vue/types/options'
import { isEmpty } from 'lodash-es'

let isInstalled = false
if (!isInstalled) {
  isInstalled = true
  Vue.directive('one-testid', {
    inserted: (el: HTMLElement, binding: DirectiveBinding) => {
      if (binding.value) {
        const datasetEl = document.querySelectorAll(`[data-testid="${binding.value}"]`)
        if (isEmpty(datasetEl)) {
          el.dataset.testid = binding.value
        } else {
          console.error(new Error(
            `[oneTestID] ID ${binding.value} is already used for the DOM. Data-testid must be unique.`,
          ))
        }
      }
    },
  })
}
