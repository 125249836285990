import { ActionTree, ActionContext } from 'vuex'
import { Categories } from '@one/types'
import { AxiosResponse } from 'axios'
import { RootState } from '../index'
import { CategoriesState } from './types'
import { mt } from './mutations'

export const actions: ActionTree<CategoriesState, RootState> = {
  fetchCategories({ commit }: ActionContext<CategoriesState, RootState>) {
    const app: any = this.app
    app.wait.start('categories-fetching')
    return this.$api.catalog.app
      .getCategories()
      .then((results: AxiosResponse<Categories.Category[]>) => {
        commit(mt.SET_CATEGORIES, results.data)
        app.wait.end('categories-fetching')
        return Promise.resolve(results.data)
      })
  },
  selectCategory(
    { commit, state }: ActionContext<CategoriesState, RootState>,
    category: string | null,
  ) {
    commit(mt.SELECT_CATEGORY, category)
    const tree: Array<string> = []
    const traverseCategoriesTree = (
      arr: Array<string>,
      categoryId: string,
    ): void => {
      const cat: Categories.Category = state.categories[categoryId]
      if (cat) {
        arr.unshift(categoryId)
        if (cat.parent) {
          return traverseCategoriesTree(arr, cat.parent)
        }
      }
    }
    if (category) {
      traverseCategoriesTree(tree, category)
    }
    commit(mt.SET_SELECTED_CATEGORIES_TREE, tree)
    commit(
      {
        type: 'products/CLEAR_LOADED',
      },
      { root: true },
    )
    return Promise.resolve()
  },
}
export default actions
